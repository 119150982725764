@import "../../../../assets/scss/abstracts/functions";
@import "../../../../assets/scss/abstracts/variables";

$select_height: 50px;

.form_control {
  position: relative;
  margin-bottom: 16px;
}

.form_control--no-margin {
  margin-bottom: 0;
}

.label {
  color: $dark;
  font-size: pxToRem(14);
  font-weight: 500;
  line-height: pxToRem(16.8);
  display: inline-block;
  margin-bottom: pxToRem(8);
}

.select {
  height: $select_height;
  background-color: #f8f8f8;
  font-size: pxToRem(14);
  color: $dark;
  padding: 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-left: 10px;
  border: 2px solid transparent;
  transition: all 0.4s;
  font-weight: 400;
  width: 100%;
  outline: none;
  &:focus {
    border-color: var(--brand-color);
    outline: none;
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba($color: $dark, $alpha: 0.4);
    font-size: pxToRem(14);
    opacity: 1; /* Firefox */
  }

  &:-ms-select-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba($color: $dark, $alpha: 0.45);
    font-size: pxToRem(14);
  }

  &::-ms-select-placeholder {
    /* Microsoft Edge */
    color: rgba($color: $dark, $alpha: 0.45);
    font-size: pxToRem(14);
  }
}

.prefix {
  position: absolute;
  top: 12px;
  left: 10px;
}
.select--prefix {
  padding-left: 48px;
}

.select--error {
  border-color: $red;
}

.select_holder {
  position: relative;
}

.error_message {
  font-size: pxToRem(12);
  display: block;
  color: $red;
  margin-top: 5px;
  font-weight: 500;
}

.password_toggle {
  color: var(--brand-color);
  font-size: pxToRem(12);
  right: 10px;
  position: absolute;
  bottom: 15px;
  text-decoration: underline;
}
